export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-2.jpg',
        userName: 'Aziza Botirova',
        email: 'aziza@gmail.com',
        authority: ['admin', 'user'],
        password: '123Qwe',
        accountUserName: 'admin',
    },

    {
        id: '22',
        avatar: '/img/avatars/thumb-2.jpg',
        userName: 'Mahmudjon Murodov',
        email: 'mahmudjonmurodov@gamil.com',
        authority: ['admin', 'user'],
        password: 'mahmudjon005',
        accountUserName: 'mahmudjonmurodov',
    },
]
